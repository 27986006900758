<template>
    <div class="system-profit">
        <h3>Сумма комиссий ставок</h3>

        <div>
            <table>
                <tr>
                    <td v-for="year in years" v-bind:key="year">
                        <button v-on:click="selectYear(year)">{{year}}</button>
                    </td>                    
                </tr>
            </table>
        </div>

        <p><small>По дате матча. Статусы: WON, LOSS, REFUND</small></p>
        <div>
            <table class="table">
                <tr>
                    <th>Месяц</th>
                    <th class="text-right">Сумма, $</th>
                </tr>
                <tr v-for="item in profits" v-bind:key="item.month">
                    <td>{{item.month}}</td>
                    <td class="text-right">{{item.profit}}</td>
                </tr>
                <tr>
                    <td><b>Всего</b></td>
                    <td class="text-right"><b>{{total}}</b></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
  import UserBetService from "../services/UserBetService";
  export default {
    name: "SystemProfit",
    data(){
      return {
        total: 0,
        profits: [],
        years: [2024, 2025],
        selectedYear: null
      }
    },

    mounted() {
        this.selectYear(this.years[this.years.length-1]);
    },
    methods: {
        loadProfits() {
            var self = this;
            UserBetService.getUserBetSystemProfit(self.selectedYear)
            .then((response) => {

                var keys = Object.keys(response.data).sort();

                var profits = []

                for(var i=0; i<keys.length; i++) {
                    var k = keys[i];
                    if(k === "total") {
                        self.total = response.data[k]
                    } else {
                        profits.push({
                            month: k,
                            profit: response.data[k]
                        })
                    }

                }
                self.profits = profits

            })
        },

        selectYear(year) {
            this.selectedYear = year;
            this.loadProfits()
        }
    }

  }
</script>

<style scoped>

</style>